





































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { AppVersionCpt, AuthStatusCpt } from "@/core/components";
import { HeaderSearchCpt, MenuCpt, MainMenuCpt } from "@/layouts/components";
import { AccountService, ConfigurationService } from "@/core/services";
import HeaderCompanySearchBarCpt from "./HeaderCompanySearchBarCpt.vue";
import { WordpressEmbedCpt } from "@/modules/integration";
import HelpPanel from "@/modules/search/components/HelpPanelCpt.vue";

export const HeaderEvents = {
  SearchQueryChanged: "searchQueryChanged",
};

@Component({
  components: {
    WordpressEmbedCpt: () => Promise.resolve(WordpressEmbedCpt),
    AuthStatusCpt: () => Promise.resolve(AuthStatusCpt),
    HeaderSearchCpt: () => Promise.resolve(HeaderSearchCpt),
    MenuCpt: () => Promise.resolve(MenuCpt),
    HelpPanel: () => Promise.resolve(HelpPanel),
    MainMenuCpt: () => Promise.resolve(MainMenuCpt),
    AppVersionCpt: () => Promise.resolve(AppVersionCpt),
    HeaderCompanySearchBarCpt: () => Promise.resolve(HeaderCompanySearchBarCpt),
  },
})
export default class HeaderCpt extends AppVue {
  @Prop({ default: "projectSearch" }) searchType: string;
  drawer = false;
  helpDrawer = false;
  infoDrawer = false;
  helpContext = "";
  form = {
    query: "",
  } as any;
  created() {
    this.$eventHub.$off("HelpRequest");
    this.$eventHub.$on("HelpRequest", this.openHelp);
  }
  isActive(routeName: string) {
    return this.$route.name === routeName;
  }
  menuSelect(e: string) {
    this.drawer = false;
  }
  openHelp(context: string) {
    this.helpContext = context;
    this.helpDrawer = true;
  }
  async logout() {
    await AccountService.logout();

    this.notify("Success", "You are now logged out.", "success");

    this.$router.push("/login").catch(() => {});
  }
  destroyed() {
    // this method is called just after the render. Why? I have no clue.
    // the component is still on the screen so it's lifecycle is not completed yet.
    // I can't remove listeners in this point because they would be removed on creation.
  }
}
