



















































































import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import { AppVersionCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";
import MenuCpt from "./MenuCpt.vue";
import MainMenuCpt from "./MainMenuCpt.vue";

@Component({
  components: {
    MenuCpt: () => Promise.resolve(MenuCpt),
    MainMenuCpt: () => Promise.resolve(MainMenuCpt),
    AppVersionCpt: () => Promise.resolve(AppVersionCpt),
  },
})
export default class BottomNavCpt extends AppVue {
  drawer = false;
  infoDrawer = false;
  isActive(routeName: string) {
    return this.$route.name === routeName;
  }
  menuSelect(e: string) {
    this.drawer = false;
  }
  home() {
    this.$emit("loadingStart");
    this.$router.push("/dashboard").catch(() => {});
  }
  search() {
    this.$emit("loadingStart");
    this.$router.push("/search").catch(() => {});
  }
  tracking() {
    this.$emit("loadingStart");
    this.$router.push("/tracking").catch(() => {});
  }
  exportHandler() {
    this.$emit("loadingStart");
    this.$router.push("/exports").catch(() => {});
  }
  async created() {}

  async mounted() {}
}
