










import { Component, Vue } from "vue-property-decorator";
import { HeaderCpt } from "./components";

@Component({
  components: {
    HeaderCpt,
  },
})
export default class TableLayout extends Vue {}
