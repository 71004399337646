



















































import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import {
  CurrentProfile,
  SavedCompanySearchService,
  SavedSearchService,
} from "@/core/services";
import { SearchService } from "@/core/services";
import { BlankSlateCpt } from "@/core/components";
import { SavedSearchModel, SearchModel } from "@/core/models";
import EventBus from "@/core/components/this.$eventHub.vue";

@Component({
  components: {
    BlankSlateCpt,
  },
})
export default class SavedSearchPopupCpt extends AppVue {
  @Prop({ default: 5 }) max: number;
  @Prop({ default: false }) showDelete: boolean;
  @Prop({ default: true }) showViewAll: boolean;
  @Prop({ default: true }) showProjectSearches: boolean;
  @Prop({ default: true }) showCompanySearches: boolean;

  loading = false;
  model: SavedSearchModel[] = [];
  companies: SavedSearchModel[] = [];
  rowClass(currentId: number) {
    const classObject = {
      "saved-search": true,
      selected:
        this.$route &&
        this.$route.params &&
        currentId === Number(this.$route.params.filterId),
    };
    return classObject;
  }
  mounted() {}

  created() {
    this.$eventHub.$on("savedSearchListUpdated", this.initializeModel);
    this.initializeModel().then(() => {
      this.runSearches();
    });
  }
  destroyed() {
    this.$eventHub.$off("savedSearchListUpdated", this.initializeModel);
  }

  private async runSearch(searchId: number) {
    this.$emit("click");
    if (searchId !== Number(this.$route.params.filterId)) {
      this.$router
        .push({
          name:
            CurrentProfile.currentProfile?.defaultView == 2
              ? "TableSearchProjects"
              : "SearchProjects",
          params: {
            projectsLoaded: "100",
            filterId: searchId.toString(),
            clearCache: "1",
          },
        })
        .catch(() => {});
    }
  }

  private async runCompanySearch(searchId: number) {
    this.$emit("click");
    if (searchId !== Number(this.$route.params.filterId)) {
      this.$router
        .push({
          name:
            CurrentProfile.currentProfile?.defaultView == 2
              ? "TableSearchCompanies"
              : "SearchCompanies",
          params: {
            projectsLoaded: "100",
            filterId: searchId.toString(),
            clearCache: "1",
          },
        })
        .catch(() => {});
    }
  }

  private async runSearches() {
    this.model.forEach(this.runSavedSearch);
    this.companies.forEach(this.runCompanySavedSearch);
  }

  private async deleteSearch(searchId: number) {
    await SavedSearchService.delete(searchId);
    this.initializeModel();
  }

  private async runCompanySavedSearch(
    searchModel: SavedSearchModel,
    ix: number,
  ) {
    this.companies[ix].isLoading = false;
    const searchFilter = await SavedCompanySearchService.getSingle(
      searchModel.userAzureSearchID,
    );
    const results = await SearchService.searchCompany(
      searchFilter.freeText,
      0,
      0,
      searchFilter,
      "",
    );
    this.companies[ix].updates = results.totalRecords;
    this.companies[ix].isLoading = false;
  }

  private async runSavedSearch(searchModel: SavedSearchModel, ix: number) {
    this.model[ix].isLoading = false;
    const searchFilter = await SavedSearchService.getSingle(
      searchModel.userAzureSearchID,
    );
    const results = await SearchService.search(
      searchFilter.freeText,
      0,
      0,
      searchFilter,
      [],
      "",
    );
    this.model[ix].updates = results.totalRecords;
    this.model[ix].isLoading = false;
  }

  private async initializeModel() {
    this.loading = true;
    try {
      this.model = await SavedSearchService.list(this.max);
      this.companies = await SavedCompanySearchService.list(this.max);
    } finally {
      this.loading = false;
    }
  }
}
