












import { Component, Vue } from "vue-property-decorator";
import { BottomNavCpt, HeaderCpt } from "./components";

@Component({
  components: {
    HeaderCpt,
    BottomNavCpt: () => Promise.resolve(BottomNavCpt),
  },
})
export default class Basic extends Vue {}
