
























import { Component, Vue } from "vue-property-decorator";
import { BottomNavCpt, HeaderCpt } from "./components";

@Component({
  components: {
    HeaderCpt,
    BottomNavCpt: () => Promise.resolve(BottomNavCpt),
  },
})
export default class MapLayout extends Vue {
  isMobileView = false;
  isDetailViewOpen = false;
  created() {
    this.$eventHub.$on("toggleMap", this.toggleMap);
    this.$eventHub.$on("closeDetailView", this.closeDetailView);
    this.$eventHub.$on("openDetailView", this.openDetailView);
  }
  destroyed() {
    this.$eventHub.$off("toggleMap", this.toggleMap);
    this.$eventHub.$off("closeDetailView", this.closeDetailView);
    this.$eventHub.$off("openDetailView", this.openDetailView);
  }
  toggleMap() {
    this.isMobileView = !this.isMobileView;
  }
  closeDetailView() {
    this.isDetailViewOpen = false;
  }
  openDetailView() {
    this.isDetailViewOpen = true;
  }
}
