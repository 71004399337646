

































import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService, ConfigurationService } from "@/core/services";
import {} from "@/core/components";

import { ProfileModel } from "@/core/services";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class MainMenuCpt extends AppVue {
  isCompanySearchEnabled = false;
  profile = new ProfileModel();
  get IsCompanySearchEnabled() {
    return this.isCompanySearchEnabled;
  }

  get projectUrl() {
    if (this.profile.defaultView === 1) {
      return "/search";
    }
    return "/t/search";
  }

  get companyUrl() {
    if (this.profile.defaultView === 1) {
      return "/companysearch/100";
    }
    return "/t/companysearch/100";
  }

  async created() {
    const config = await ConfigurationService.getInterfaceConfig();
    if (config === null) {
      this.$router.push("/login").catch(() => {});
    }
    this.isCompanySearchEnabled =
      config?.isCompanySearchEnabled || this.isCompanySearchEnabled;

    this.profile = await AccountService.getProfile();

    this.$eventHub.$on("default_set", (defaultViewId: number) => {
      this.profile.defaultView = defaultViewId;
    });
  }

  isActive(routeName: string) {
    return this.$route.name === routeName ? "is-active" : "is-inactive";
  }

  async mounted() {}
}
