




































































import { Component, Vue } from "vue-property-decorator";
import EventBus from "@/core/components/this.$eventHub.vue";

export const HeaderEvents = {
  SearchQueryChanged: "searchQueryChanged",
};
import { events as SearchBarEvents } from "@/modules/search/components/SearchFilterBarCpt.vue";
import { IUIFilters, ProjectService } from "../../core/services";
import SavedSearchPopupCpt from "./SavedSearchPopupCpt.vue";
import { RawLocation } from "vue-router";
import { FreeTextSearchType } from "@/core/models";

@Component({
  components: {
    SavedSearchPopupCpt: () => Promise.resolve(SavedSearchPopupCpt),
  },
})
export default class HeaderCompanySearchBarCpt extends Vue {
  form = {
    query: "",
    queryType: FreeTextSearchType.Any,
  } as any;
  saveSearchesVisible = false;

  toggleSavedSearches() {
    this.saveSearchesVisible = !this.saveSearchesVisible;
  }
  created() {}

  mounted() {
    this.form.query = this.$route.params.query || "";
    this.form.queryType = this.$route.params.queryType || FreeTextSearchType.Any;
    this.$eventHub.$on(SearchBarEvents.ClearFilters, this.clearFilters);
    this.$eventHub.$on(SearchBarEvents.SetDefaultFilters, this.setDefaultFilters);
  }
  destroyed() {
    this.$eventHub.$off(SearchBarEvents.ClearFilters, this.clearFilters);
    this.$eventHub.$off(SearchBarEvents.SetDefaultFilters, this.setDefaultFilters);
  }

  clearFilters() {
    this.form.query = "";
  }

  setDefaultFilters(filters: IUIFilters) {
    this.form.query = filters.query || "";
    this.form.queryType = Number(filters.queryType) || FreeTextSearchType.Any;
  }
  async catchEnter(keyUpEvent: KeyboardEvent) {
    await this.searchQueryUpdated(this.form);
  }
  async clearHandler() {
    await this.searchQueryUpdated("");
  }
  async searchQueryUpdated(event: any) {
    const queryNumber = Number(event.query);
    if (queryNumber) {
      if (await this.goToProjectDetailPage(queryNumber)) {
        return;
      }
    }
    if (this.$route.name !== "SearchCompanies") {
      const routeParameters: RawLocation = {
        name: "SearchCompanies",
        params: {
          query: event.query,
          queryType: event.queryType,
          projectsLoaded: "100",
          clearCache: "1",
          reload: "true",
        },
      };
      this.$router.push(routeParameters).catch(() => {});
    } else {
      this.$eventHub.$emit(HeaderEvents.SearchQueryChanged, event);
    }
  }
  async goToProjectDetailPage(queryNumber: number) {
    const res = await ProjectService.isProject(queryNumber);
    if (res) {
      this.$router
        .push({
          name: "Project",
          params: {
            projectId: queryNumber.toString(),
          },
        })
        .catch(() => {});
      return true;
    }
    return false;
  }
}
