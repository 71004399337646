import Vue from "vue";
// import "./styles/variables.scss";
import "element-theme-chalk/src/menu-item.scss";
import "element-theme-chalk/src/menu.scss";
import "element-theme-chalk/src/header.scss";
import "element-theme-chalk/src/card.scss";
// import "element-theme-chalk/src/button.scss";
import "element-theme-chalk/src/main.scss";
import "element-theme-chalk/src/col.scss";
import "element-theme-chalk/src/row.scss";
import "element-theme-chalk/src/icon.scss";
import "element-theme-chalk/src/popover.scss";
import "element-theme-chalk/src/container.scss";
// import "element-theme-chalk/src/tabs.scss";
// import "element-theme-chalk/src/tab-pane.scss";
import "element-theme-chalk/src/input.scss";
import "element-theme-chalk/src/input-number.scss";
// import "element-theme-chalk/src/badge.scss";
import "element-theme-chalk/src/alert.scss";
import "element-theme-chalk/src/avatar.scss";
import "element-theme-chalk/src/dropdown.scss";
import "element-theme-chalk/src/dropdown-item.scss";
import "element-theme-chalk/src/dropdown-menu.scss";
// import "element-theme-chalk/src/dialog.scss";
// import "element-theme-chalk/src/table.scss";
// import "element-theme-chalk/src/table-column.scss";
import "element-theme-chalk/src/loading.scss";
// import "element-theme-chalk/src/tooltip.scss";
import "element-theme-chalk/src/breadcrumb.scss";
import "element-theme-chalk/src/breadcrumb-item.scss";
import "element-theme-chalk/src/date-picker.scss";
import "element-theme-chalk/src/form.scss";
import "element-theme-chalk/src/form-item.scss";
import "element-theme-chalk/src/notification.scss";
// import "element-theme-chalk/src/timeline.scss";
// import "element-theme-chalk/src/timeline-item.scss";
// import "element-theme-chalk/src/slider.scss";
import "element-theme-chalk/src/popper.scss";
import "element-theme-chalk/src/option.scss";
import "element-theme-chalk/src/option-group.scss";
import "element-theme-chalk/src/scrollbar.scss";
import "element-theme-chalk/src/select.scss";
import "element-theme-chalk/src/select-dropdown.scss";
// import "element-theme-chalk/src/tree.scss";
import "element-theme-chalk/src/drawer.scss";

import ElementUI from "element-ui";
import VueMobileDetection from "vue-mobile-detection";
import "./styles/app.scss";
import locale from "element-ui/lib/locale/lang/en";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "vue-tour/dist/vue-tour.css";

import App from "./App.vue";
import VueAxios from "vue-axios";
import VueMoment from "vue-moment";
import axios from "axios";

import VueTour from "vue-tour";
import VueAuthenticate from "vue-authenticate";

import router from "./router";
import "./registerServiceWorker";
import VueAppInsights from "vue-application-insights";

import { Default, Empty, Basic, MapLayout, TableLayout, LeftBarLayout } from "@/layouts";
import VueGtag from "vue-gtag";
import Hotjar from "vue-hotjar";

import * as F from "./core/filters";
import { Route } from "vue-router";
import { AuthTokenService } from "./core/services";
import { zohoManager } from "./zoho/zoho";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.component("default-layout", Default);
Vue.component("empty-layout", Empty);
Vue.component("basic-layout", Basic);
Vue.component("map-layout", MapLayout);
Vue.component("table-layout", TableLayout);
Vue.component("leftbar-layout", LeftBarLayout);

Vue.filter("b$", F.b$);
Vue.filter("cisDate", F.cisDateFilter);
Vue.filter("cisDateTime", F.cisDateTimeFilter);
Vue.filter("cisTime", F.cisTimeFilter);
Vue.filter("cisMonth", F.cisDateMonthFilter);
Vue.filter("cisDateExtended", F.cisDateExtendedFilter);
Vue.filter("recallDateFilter", F.recallDateFilter);
Vue.filter("recallDateDayFilter", F.recallDateDayFilter);
Vue.filter("cisDeadlineDateTimeFilter", F.cisDeadlineDateTimeFilter);
Vue.filter("toMoney", F.toMoney);
Vue.filter("toCisValue", F.toMoneyValue);
Vue.filter("minutes", F.fromMinuteHumanReadable);

Vue.filter("truncate", F.truncateFilter);
Vue.filter("currency", F.currencyFilter);
Vue.filter("withDecimals", F.withDecimalsFilter);
Vue.filter("number", F.numberFormatterFilter);
Vue.filter("mSquared", F.m2FormatterFilter);
Vue.filter("area", F.areaFormatterFilter);
Vue.filter("yesNo", F.yesNoFormatter);

Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();
Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_WEBAPI_URL,
  loginUrl: "account/login",
  tokenPrefix: "auth",
  tokenName: "token",
});
Vue.use(ElementUI, { locale });
Vue.use(VueMoment);
Vue.use(VueAppInsights, {
  id: process.env.VUE_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
  router,
});

Vue.use(VueTour);
Vue.use(
  VueGtag,
  {
    config: { id: "UA-4640173-3" },
  },
  router,
);
Vue.use(Hotjar, {
  id: "1982794", // Hotjar Site ID
});

Vue.use(VueMobileDetection);

const vm = new Vue({
  router,
  render: h => h(App),
}).$mount("#app");