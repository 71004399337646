















import { Component, Vue } from "vue-property-decorator";
import { BottomNavCpt, HeaderCpt } from "./components";
import { FooterCpt } from "@/core/components";

@Component({
  components: {
    HeaderCpt,
    FooterCpt,
    BottomNavCpt: () => Promise.resolve(BottomNavCpt),
  },
})
export default class Default extends Vue {}
