














































import { Prop, Vue, Component } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { AccountService, AuthTokenService, SearchService, WatcherService } from "@/core/services";

@Component({
  components: {},
})
export default class MenuCpt extends AppVue {
  @Prop({ default: "horizontal" }) direction: string;

  isActive(routeName: string) {
    return this.$route.name === routeName;
  }
  menuOpen(e: any) {
    this.$emit("select", e);
  }
  get isImpersonating() {
    return AuthTokenService.isImpersonating();
  }
  async logout() {
    if (this.isImpersonating) {
      await AccountService.logout();
      SearchService.clearCache();
      WatcherService.clearCache();
      await AccountService.restoreToken();
      this.notify("Success", "Impersonation terminated.", "success");
      this.$router
        .push("/admin/users/search")
        .then(() => {
          window.location.reload();
        })
        .catch(() => {});
    } else {
      await AccountService.logout();
      this.notify("Success", "You are now logged out.", "success");
      SearchService.clearCache();
      WatcherService.clearCache();
      this.$router.push("/login").catch(() => {});
    }
  }
}
